<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('StartPagesCreate')"
    @edit="(id) => onEdit('StartPagesEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'StartPages',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'StartPages',
      tableCaption: 'Стартовые страницы',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Стартовые страницы',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: '' },
        {
          text: 'Название',
          alias: 'name',
          order: '',
        },
        { text: 'Внутренняя ссылка', alias: 'url', order: '' },
        { text: '', alias: 'actions', order: '' },
      ],
    };
  },
};
</script>
